<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              :label="$t('labels.name')"
              label-for="name"
            >
              <b-form-input
                id="name"
                :value="searchFields.name"
                @input="inputChange($event, 'name')"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>{{ $t('labels.team_type') }}</label>
            <v-select
              v-model="searchFields.team_type"
              :dir="$store.state.appConfig.layout.direction"
              label="label"
              :options="team_types"
              :reduce="item => item.value"
              :placeholder="$t('labels.team_type')"
            />
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>{{ $t('labels.select_league') }}</label>
            <v-select
              v-model="searchFields.league_id"
              :dir="$store.state.appConfig.layout.direction"
              label="title_label"
              :options="leagues"
              :reduce="item => item.id"
              :placeholder="$t('labels.select_league')"
            />
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>{{ $t('labels.status') }}</label>
            <v-select
              v-model="searchFields.status"
              :dir="$store.state.appConfig.layout.direction"
              :options="statuses"
              :reduce="item => item.id"
              label="name"
              class="w-100"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <TableIndex
      :resource-name="resourceName"
      :singular-name="singularName"
      :filter-fields="searchFields"
      :has-export="'teams'"
      :has-import="'teams'"
      :has-import-multi="'coaches'"
      :has-export-multi="'coaches'"
    />
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin'

export default {
  mixins: [listMixin],
  data() {
    return {
      resourceName: 'teams',
      singularName: 'team',
      team_types: [],
      leagues: [],
      searchFields: {
        name: null,
        status: null,
        league_id: null,
        team_type: null,
      },
    }
  },
  methods: {
    // eslint-disable-next-line func-names
    inputChange: _.debounce(function (e, field) {
      this.searchFields[field] = e
    }, 500),
    getTeamTypes() {
      this.axios.get('/lists/teams/types')
        .then(res => {
          this.team_types = res.data
        })
    },
    getLeagues() {
      this.axios.get('/lists/League')
        .then(res => {
          this.leagues = res.data
        })
    },
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    statuses() {
      return [
        {
          id: 'active',
          name: this.$t('statuses.active'),
        },
        {
          id: 'inactive',
          name: this.$t('statuses.inactive'),
        },
      ]
    },
  },
  created() {
    this.getTeamTypes()
    this.getLeagues()
  },
}
</script>
